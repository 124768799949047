@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus {
  outline: 0;
}

@layer utilities {
  .tw-default-text-color {
    @apply tw-text-blue-600;
  }

  .tw-default-bg-color {
    @apply tw-bg-blue-600;
  }

  .tw-bg-secondary {
    background: #f7f7f8 !important;
  }
}

@layer components {
  .tw-button {
    @apply tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-px-6 tw-py-2 tw-text-base;
  }

  .tw-button-primary {
    @apply tw-border-blue-500 tw-bg-blue-500 tw-text-white;
  }

  .tw-button-primary:hover {
    @apply tw-border-blue-600 tw-bg-blue-600 tw-text-white;
  }

  .tw-button-secondary {
    @apply tw-border-gray-200 tw-bg-white tw-text-gray-600;
  }

  .tw-button-secondary:hover {
    @apply tw-bg-gray-100;
  }

  .form-group {
    @apply tw-mb-6;
  }

  .form-label {
    @apply tw-mb-1 tw-flex tw-text-base tw-font-medium tw-leading-5 tw-text-gray-700;
  }

  .form-input:focus,
  .form-textarea:focus,
  .form-select:focus {
    @apply tw-border-blue-200 tw-shadow-md tw-shadow-blue-200 tw-outline-2 tw-outline-offset-2;
  }
}

.tw-button[disabled] {
  cursor: not-allowed;
  opacity: 0.75;
}
.tw-button:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}
.tw-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.tw-button.button-xs {
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.tw-button.button-sm {
  padding: 0.5rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.tw-button.button-lg {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.tw-button.button-xl {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.datetimepicker.form-label:not(.visible):not(.inline) {
  display: none;
}
.form-input,
.form-textarea,
.form-select {
  width: 100%;
  border-radius: 0.5rem;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem 1rem;
}

.form-input[readonly],
.form-textarea[readonly],
.form-select[readonly] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.form-input[disabled],
.form-textarea[disabled],
.form-select[disabled] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
  opacity: 0.75;
}
.form-input.prepend,
.form-textarea.prepend,
.form-select.prepend {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0px;
}
.form-input.append,
.form-textarea.append,
.form-select.append {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0px;
}
.form-select {
  padding-right: 3rem;
}
.form-checkbox,
.form-radio {
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.form-checkbox:focus,
.form-radio:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
  --tw-ring-offset-width: 0px;
}
.form-checkbox {
  border-radius: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.form-radio {
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.form-alert {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: flex;
  border-radius: 0.375rem;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.datetimepicker.form-alert:not(.visible):not(.inline) {
  display: none;
}
.form-error {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

textarea:focus {
  --tw-ring-shadow: none;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-shadow: none;
  --tw-ring-offset-shadow: none;
  box-shadow: none;
}
